<template>
    <v-container fluid>
        <div class="space-above"></div>
        <v-row class="logo-maria" align="center">
            <img src="@/assets/images/talktomaria.jpg" alt="" style="width:200px">
        </v-row>
        <br><br>
        <v-row  class="logo-maria" align="center">
            <span>Hi <b>{{ fb_fname + ' ' + fb_lname }}</b>, Close the window to go back to messenger if the page does not automatically close within <b>{{ countDown }}</b> seconds.</span>
        </v-row>
        <div class="space-above"></div>
        <div class="space-above"></div>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {

    computed: {
        ...mapGetters([
            'fb_fname',
            'fb_lname',
        ]),
    },

    data: function() {
        return{
            countDown : 5
    
        }
    },
    methods:{
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        closeWebview: function(){
            window.MessengerExtensions.requestCloseBrowser(function success(){
            },function error(err){
                alert(err);
            });
        },
    
    },
    created() {
        this.closeWebview()
        this.countDownTimer()
    }

}
</script>

<style>

</style>